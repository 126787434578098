import React, { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { useLocation, useNavigate } from "@remix-run/react";

export default function Tabs({ tabs, titles }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hash = location.hash.substr(1); // Remove the '#' from the hash
    if (hash) {
      const formattedHash = hash
        .split("-")
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      const index = titles.findIndex(title => title === formattedHash);
      if (index !== -1) {
        setSelectedTab(index);
      }

    }
  }, [location.hash, titles]);

  const handleTabClick = (index, title) => {
    setLoading(true); // Show loader when tab is clicked
    window.location = location.pathname + location.search + "#" + title.toLowerCase().replace(/ /g, "-")

    // Hide loader after 1 second
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <Tab.Group selectedIndex={selectedTab}>
      {loading && (
        <div className="loading"><span className="loader"></span></div>
      )}
      <Tab.List className="tabs-list light-border-space fade-in flex justify-center space-x-4">
        {titles.map((title, index) => (
          <Tab
            key={index}
            className="tab-item px-6 py-3 text-sm font-medium rounded-md transition-colors duration-200 ease-in-out focus:outline-none"
            onClick={() => handleTabClick(index, title)}
          >
            {title}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels>
        {tabs.map((tabContent, index) => (
          <Tab.Panel key={index} className="mt-4 tabs-panel fade-in" >
            {tabContent}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
}
