import { useEffect, useRef, useState } from "react";
import { json } from "@remix-run/node";
import {
  Form,
  useFetcher,
  useLoaderData,
  useTransition,
} from "@remix-run/react";

import { db } from "../utils/db.server";
import { getWaypointResults, getFromCache, setToCache } from "../helpers/waypoints";
import { getSession, commitSession } from "../services/session.server";
import Button from "../components/ui/Button";
import Tabs from "../components/ui/Tabs";
import Arrow from "../components/icons/Arrow";
import Page from "../components/layout/Page";
import Header from "../components/layout/Header";
import Main from "../components/layout/Main";
import Footer from "../components/layout/Footer";
import Alert from "../components/ui/Alert";
import Home from "../components/pages/Home";
import { DESCRIPTIONS, TITLES } from "../meta";
import { getAirports } from "../utils/airports.server";
import { tripBuilderLoader, stateLoader, generateCacheKey } from "~/helpers/waypoints.js";
import { getTrip } from "~/utils/trips.server.js";

import { getUser, updateUser } from "../utils/users.server.js";
import { log } from "~/utils/db.server.js";

const { v4: uuidv4 } = require('uuid');



export async function loader({ request }) {

  let accessToken = process.env.MAPBOX_API;

  const url = new URL(request.url);
  const searchParams = url.searchParams;
  const session = await getSession(request.headers.get("Cookie"));

  let trip_builder = await tripBuilderLoader(request.headers.get("Cookie"))
  let state = await stateLoader(request.headers.get("Cookie"))

  console.log(trip_builder)
  let email = process.env.DEFAULT_USER_EMAIL;
  let user = {
    data: {
      name: process.env.DEAFAULT_USER_NAME,
      status: process.env.DEFAULT_USER_STATUS,
      picture: `/avatar.png`
    }
  };

  let name = user.data.name;
  let status = user.data.status;
  let picture = user.data.picture;

  // I GOT HIGH

  if (typeof session.data.user !== "undefined") {
    if (typeof session.data.user.data !== "undefined") {
      if (typeof session.data.user.data.email !== "undefined") {
        email = session.data.user.data.email;
        user = await getUser(email);
        if (typeof user.data !== "undefined") {
          if (typeof user.data.name !== "undefined") {
            if (session.data.user.data.meta === null) {
              session.data.user.data.meta = {}
            }
            if (user.data === null) {
              user.data = {}
            }
            if (user.data.meta === null) {
              user.data.meta = {}
            }
            user.data.meta.trip_builder = [JSON.stringify(trip_builder)];
            session.data.user.data.meta.trip_builder = [JSON.stringify(trip_builder)];

            user.data.meta.state = [JSON.stringify(state)];
            session.data.user.data.meta.state = [JSON.stringify(state)];

            await commitSession(session);
            await updateUser(user)
            name = user.data.name;
          }
          if (typeof user.data.status !== "undefined") {
            status = user.data.status;
          }

          if (typeof user.data.picture !== "undefined") {
            picture = user.data.picture;
          }
        }
      }
    }
  }

  // I GOT HIGH

  // Coupon / Alert logic
  let message = {}
  let coupon = ''
  let trip = []
  let shared = []
  let similarResults = []

  if (searchParams.get("trip") !== null) {
    trip = await getTrip(searchParams.get("trip"))

    if (typeof trip?.data !== "undefined" && typeof trip?.data?.data !== "undefined" && typeof trip?.data?.data?.trip_builder !== "undefined" && trip?.data?.data?.trip_builder?.length) {
      shared = trip.data.data.trip_builder
      trip_builder = trip.data.data.trip_builder

    }
  }



  if (searchParams.get("message") !== null) {

    message = searchParams.get("message")

    if (typeof message?.length !== "undefined") {

      if (message === 'contact_submission') {
        message = {
          text: `<strong>Success:</strong>message sent!  💬💬💬 We will contact you shortly`,
          type: 'success',
        }
      }
    }
  }

  if (searchParams.get("coupon") !== null) {
    // TODO: add more coupon validation
    coupon = searchParams.get("coupon")
    log("COUPON USED", coupon)
    if (typeof coupon?.length !== "undefined" && coupon.length > 3) {

      message = {
        text: `<strong>Success:</strong> ${coupon} coupon successfully used! 🚀🚀🚀`,
        type: 'success',
      }
    }
  }

  const afterPostSuccess = searchParams.get("success") === "true";

  const today = new Date();
  const defaultDate = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  const defaultDate2 = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  // Defaults for now
  const origin = searchParams.get("origin")
    ? searchParams.get("origin")
    : "LAX";
  const destination = searchParams.get("destination")
    ? searchParams.get("destination")
    : "JFK";
  const exclude =
    searchParams.get("exclude") && email !== ""
      ? searchParams.get("exclude")
      : "";
  const quality =
    searchParams.get("quality") && email !== ""
      ? searchParams.get("quality")
      : 0;
  const date = searchParams.get("date")
    ? searchParams.get("date")
    : defaultDate;

  const days = searchParams.get("days")
    ? searchParams.get("days")
    : 2;
  const count =
    searchParams.get("count") && email !== ""
      ? searchParams.get("count")
      : 1;
  let page =
    searchParams.get("page")
      ? searchParams.get("page")
      : 1;
  let type =
    searchParams.get("type")
      ? searchParams.get("type")
      : 'one-way';
  const date2 = searchParams.get("date2")
    ? searchParams.get("date2")
    : defaultDate2;

  const days2 = searchParams.get("days2")
    ? searchParams.get("days2")
    : 2;
  const count2 =
    searchParams.get("count2") && email !== ""
      ? searchParams.get("count2")
      : 1;
  let page2 =
    searchParams.get("page2")
      ? searchParams.get("page2")
      : 1;

  let results = []
  let results2 = []
  let pageMax = 10
  let pageMax2 = 10

  // Check if data exists in cache, otherwise fetch from the database
  let airportsAll = await getFromCache('airportsAll');
  if (!airportsAll || airportsAll === 'error') {
    airportsAll = await getAirports();
    await setToCache('airportsAll', airportsAll);
  }

  let users = await getFromCache('usersCount');
  if (!users) {
    users = await db.user.count({});
    await setToCache('usersCount', users);
  }

  let airports = await getFromCache('airportsCount');
  if (!airports) {
    airports = await db.airport.count({});
    await setToCache('airportsCount', airports);
  }


  let flights = await getFromCache('flightsCount');
  if (!flights) {
    flights = await db.flight.count({});
    await setToCache('flightsCount', flights);
  }

  let waypoints = await getFromCache('waypointsTotal');
  if (!waypoints) {
    waypoints = 0
  }

  try {

    let similarResults = [];
    const baseDate = new Date(date);

    for (let offset = -5; offset <= 5; offset++) {
      const currentDate = new Date(baseDate);
      currentDate.setDate(baseDate.getDate() + offset);


      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      const formattedDateDisplay = `${month}/${day}`;
      const data = await getFromCache(generateCacheKey('getWaypointResults', origin, destination, formattedDate, days, count, exclude, quality));
      similarResults.push({ "data": data, "date": formattedDate, "dateDisplay": formattedDateDisplay });
    }


    similarResults = similarResults.map(resultArray => {
      if (!resultArray.data) {
        return null; // Skip if resultArray is null
      }
      for (let flight of resultArray.data) {
        if (flight.direct === true || typeof flight.direct === 'undefined') {
          return { "date": resultArray.date, "dateDisplay": resultArray.dateDisplay, "price": flight.waypoint.price };
        }
      }
      return null; // or handle the case where no matching flight is found
    });



    results = await getWaypointResults(
      db,
      origin,
      destination,
      date,
      days,
      count,
      exclude,
      quality
    );

    pageMax = Math.ceil(results.length / 15);

    results = results.slice((page - 1) * 15, (page - 1) * 15 + 15);

    pageMax2 = 10


    results2 = await getWaypointResults(
      db,
      destination,
      origin,
      date2,
      days,
      count,
      exclude,
      quality
    );

    pageMax2 = Math.ceil(results2.length / 15);

    results2 = results2.slice((page2 - 1) * 15, (page2 - 1) * 15 + 15);

    if (!waypoints) {
      waypoints = results.length + results2.length
      await setToCache('waypointsTotal', waypoints);
    }


    if (type !== 'one-way' && date2 < date) {
      message = {
        text: `<strong>Warning:</strong> Your return date is before your departure date.`,
        type: 'warning',
      }
    }
    return json(
      {
        afterPostSuccess,
        results,
        results2,
        similarResults,
        destination,
        date,
        days,
        count,
        page,
        pageMax,
        date2,
        days2,
        count2,
        page2,
        pageMax2,
        origin,
        exclude,
        quality,
        airports,
        waypoints,
        flights,
        users,
        airportsAll,
        email,
        name,
        status,
        picture,
        session,
        accessToken,
        trip_builder,
        message,
        coupon,
        state,
        shared
      },
      {
        headers: {
          "Cache-Control": "max-age=60, stale-while-revalidate=30",
        },
      }
    );
  } catch (error) {
    console.error(error);

    return {
      afterPostSuccess,
      results,
      results2,
      similarResults,
      destination,
      date,
      days,
      count,
      page,
      pageMax,
      date2,
      days2,
      count2,
      page2,
      pageMax2,
      origin,
      exclude,
      quality,
      airports,
      waypoints,
      flights,
      users,
      airportsAll,
      email,
      name,
      status,
      picture,
      session,
      accessToken,
      trip_builder,
      message,
      coupon,
      state,
      shared,
      error: "Unexpected error happened on server!",
    };
  }
}

export async function action({ request }) {
  const formData = await request.formData();

  const email = formData.get("email");
  const query = formData.get("query");

  if (email && query) {
    try {
      await db.notification.create({
        data: {
          email: email,
          query: query,
        },
      });

      return {
        result: "We will let you know when we have more flights.",
      };
    } catch (error) {
      console.error(error);

      return {
        error: "Unexpected error happened on server!",
      };
    }
  } else {
    return {
      error: "Please enter a valid email.",
    };
  }
}

export function meta({ data }) {
  return {
    title: TITLES.HOME,
    description: DESCRIPTIONS.HOME,
  };
}

export default function Index() {

  const loaderData = useLoaderData();
  console.log("")
  console.log("")
  console.log("")
  console.log("")
  if (typeof window === 'object') console.log('loaderDataOFFICIAL', loaderData)
  console.log("")
  console.log("")
  console.log("")
  console.log("")
  const afterPostSuccess = loaderData?.afterPostSuccess;

  let name = loaderData?.name;
  //name = name === "DEBUG_MODE" ? 'Satoshi Nakamoto' : name; 
  const status = loaderData?.status;
  const picture = loaderData?.picture;
  const session = loaderData?.session;
  const shared = loaderData?.shared;
  const message = loaderData?.message;

  let showPitch = true;

  let state = {}

  if (typeof shared?.length !== "undefined" && shared?.length) {
    showPitch = false;
    useEffect(() => {
      window.scrollTo({
        top: 215, // Adjust the value (200) as needed for your scroll distance
        behavior: 'smooth' // Optional: Use smooth scrolling
      });
    }, []); // Empty dependency array to run this effect only once when component mounts

  }

  if (typeof loaderData?.state !== 'object' && typeof loaderData?.state !== 'undefined') {
    state = JSON.parse(JSON.parse(loaderData.state))
  }

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  const tabContents = [
    <Home loaderData={loaderData} type="one-way" key={uuidv4()} />,
    <Home loaderData={loaderData} type="round-trip" key={uuidv4()} />,
    <Home loaderData={loaderData} type="trip-builder" key={uuidv4()} />,
  ];

  return (
    <Page>

      {typeof message.text !== "undefined" && message.text.length && (typeof state?.alertClosed === 'undefined' || state?.alertClosed == false) && (
        <Alert className="content" message={message.text} type={message.type} duration={3000}></Alert>
      )}

      <Header
        home
        showPitch={showPitch}
        afterPostSuccess={afterPostSuccess}
        name={name}
        status={status}
        picture={picture}
        session={session}
      />

      <Main className="fade-in-slow main flex flex-col items-stretch justify-start gap-8">
        <Tabs
          tabs={tabContents}
          titles={["One Way", "Round Trip", "Trip Builder"]}
        />
      </Main>
      <Button
        onClick={handleScrollToTop}
        className="back-to-top pulse"
      >
        <Arrow />
      </Button>
      <Footer />
    </Page >
  );
}
